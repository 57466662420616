.servicii {
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  h2 {
    font-size: 30px;
    text-align: center;
    align-self: center;
  }

  .service {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    p {
      margin-top: 25px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .servicii-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 70px;

    .service-img-container {
      width: 103px;
      height: 95px;
      background-color: #c0c7ff;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: -2;

      img {
        width: 90%;
        z-index: -1;
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .servicii {
    .servicii-container {
      padding: 20px;
      gap: 20px;
      flex-wrap: nowrap;
    }
  }
}

@media screen and (min-width: 860px) {
  .servicii {
    .servicii-container {
      gap: 50px;
      align-items: center;
      justify-content: center;

      .service-img-container {
        width: 80px;
        height: 70px;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .servicii {
    .servicii-container {
      justify-content: space-evenly;
      .service-img-container {
        width: 103px;
        height: 95px;

        img {
          width: 90%;
        }
      }
    }
  }
}
