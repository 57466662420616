.acasa {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .acasa-logo {
    margin-top: 20px;
    width: 232px;
    height: 72px;

    img {
      width: 100%;
    }
  }
}

@media screen and (min-width: 768px) {
  .acasa {
    .acasa-logo {
      width: 300px;
      height: auto;

      img {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 860px) {
  .acasa {
    .acasa-logo {
      margin-top: 60px;
      width: 335px;
      height: auto;

      img {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .acasa {
    .acasa-logo {
      width: 480px;
      height: auto;

      img {
        width: 100%;
      }
    }
  }
}
