.sticky-order {
  display: flex;
  flex-direction: column;
  background-color: #800000;
  width: 220px;
  height: 70px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 2%;
  right: 1%;
  z-index: 99999;

  p {
    font-weight: 600;
    font-size: 18px;
  }

  a {
    text-decoration: none;
    color: #ffffff;
    font-weight: 500;
  }
}

@media screen and (min-width: 800px) {
  .sticky-order {
    display: none;
  }
}
