.hamburger {
  background-color: transparent;
  width: 35px;
  border: none;
  margin-left: 29px;
  margin-top: 12px;
  cursor: pointer;

  .hamburger-img {
    width: 100%;
    height: 100%;
  }
}
.navbar {
  display: none;
}

.navbar.show {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100vw;
  background-color: #01071c;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;

  .logo-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    align-items: center;

    .logo-link {
      margin-top: 10px;
    }

    .logo {
      width: 100%;
      height: 100%;
    }

    hr {
      width: 90%;
      color: #f5bd02;
    }

    .closeMenu {
      display: flex;
      background-color: transparent;
      border: none;
      width: 35px;
      margin-left: 29px;
      margin-top: 12px;
      cursor: pointer;
      align-self: flex-start;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    gap: 78px;
    align-items: center;

    .nav-link {
      color: #ffffff;
      font-size: 24px;
      font-weight: 400;
    }
    .nav-link:hover {
      color: #e5e2e2;
      transition: 0.3s;
    }
  }

  .comanda-acum-container {
    display: flex;
    flex-direction: column;
    width: 218px;
    height: 85px;
    background-color: #800000;
    border-radius: 20px;
    align-self: center;
    text-align: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 10px;
    a {
      text-decoration: none;
      color: #ffffff;
      font-size: 20px;
      font-weight: 600;
    }
  }
}

@media screen and (min-width: 500px) {
  .navbar.show {
    width: 224px;
  }
}

@media screen and (min-width: 1050px) {
  .hamburger {
    display: none;
  }
  .navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: 224px;
    background-color: #01071c;

    position: fixed;

    .logo-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;
      align-items: center;

      .logo-link {
        margin-top: 20px;
      }

      .logo {
        width: 100%;
        height: 100%;
      }

      hr {
        width: 90%;
        color: #f5bd02;
      }

      .closeMenu {
        display: none;
      }
    }

    .nav-links {
      display: flex;
      flex-direction: column;
      gap: 78px;
      align-items: center;

      .nav-link {
        color: #ffffff;
        font-size: 24px;
        font-weight: 400;
      }
      .nav-link:hover {
        color: #c0bbbb;
        transition: 0.3s;
      }
    }

    .comanda-acum-container {
      display: flex;
      flex-direction: column;
      width: 218px;
      height: 85px;
      background-color: #800000;
      border-radius: 20px;
      align-self: center;
      text-align: center;
      justify-content: center;
      gap: 8px;
      margin-bottom: 20px;
      a {
        text-decoration: none;
        color: #ffffff;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}
