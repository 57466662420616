.slider-container {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  .images-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 11px;
    .img-container {
      width: 366px;
      height: 518px;

      img {
        cursor: pointer;
        width: 100%;
      }
    }
  }
  .slide-navigation {
    margin-top: 15px;
    display: flex;
    gap: 23px;
    align-self: center;

    .prev-button,
    .next-button {
      cursor: pointer;
      background-color: transparent;
      border: none;
      margin-top: 0.4em;
    }

    .dots-nav {
      display: flex;
      flex-direction: row;
      gap: 10px;

      margin-top: 4px;
      .nav-dot-button {
        cursor: pointer;
        width: 29px;
        height: 26px;
        border-radius: 50%;
        border: none;
        background-color: #757575;
      }

      .nav-dot-button.active {
        background-color: #cecece;
      }
    }
  }
}

@media screen and (min-width: 860px) {
  .slider-container {
    .images-container {
      margin-top: 20px;
      margin-bottom: 20px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 30px;

      .img-container {
        width: 420px;
        height: 600px;
      }
    }
    .slide-navigation {
      .next-button,
      .prev-button {
        width: 50px;
        height: 32px;
        margin-top: 0px;
        img {
          width: 100%;
        }
      }
      .dots-nav {
        gap: 30px;

        .nav-dot-button {
          width: 41px;
          height: 39px;
          align-self: center;
        }
      }
    }
  }
}

@media screen and (min-width: 1050px) {
  .slider-container {
    .images-container {
      gap: 10px;
      flex-wrap: wrap;
      .img-container {
        width: 400px;
      }
    }

    .slide-navigation {
      .dots-nav {
        .nav-dot-button {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .slider-container {
    .images-container {
      flex-wrap: nowrap;
      img {
        height: auto;
      }
    }
  }
}
@media screen and (min-width: 1650px) {
  .slider-container {
    .images-container {
      flex-wrap: nowrap;
      .img-container {
        width: 500px;
        height: auto;
      }
    }
  }
}
@media screen and (min-width: 1850px) {
  .slider-container {
    .images-container {
      flex-wrap: nowrap;
      .img-container {
        width: 561px;
        height: auto;
        img {
          height: 790px;
        }
      }
    }
  }
}
