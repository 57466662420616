#meniu {
  margin-top: 2.7em;
  position: relative;

  .zoom-overlay-container {
    display: none;
  }

  .menu-text p {
    color: #ffffff;
    font-size: 22px;
    text-align: center;
    align-self: center;
    font-weight: 400;
  }

  .descarca-pdf {
    margin-top: 14px;
    display: flex;
    justify-content: center;

    button {
      border: none;
      cursor: pointer;
      width: 237px;
      height: 41px;
      background: #800000;
      border-radius: 7px;
      color: #ffffff;
      font-size: 28px;
    }
  }
}

@media screen and (min-width: 350px) {
  #meniu {
    .zoom-overlay-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.801);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .zoom-overlay-image-container {
        position: relative;
        border: 5px solid white;
        border-radius: 5px;
        height: 600px;

        img {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
    }
    .zoom-overlay-container.hidden {
      display: none;
    }
  }
}

@media screen and (min-width: 550px) {
  #meniu {
    .zoom-overlay-container {
      .zoom-overlay-image-container {
        width: 500px;
        height: 690px;

        img {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
    }
    .zoom-overlay-container.hidden {
      display: none;
    }
  }
}

@media screen and (min-width: 860px) {
  #meniu {
    .zoom-overlay-container {
      .zoom-overlay-image-container {
        width: 640px;
        height: auto;

        img {
          width: 100%;
          height: auto;
          cursor: pointer;
        }
      }
    }
    .zoom-overlay-container.hidden {
      display: none;
    }
    .menu-text {
      p {
        font-size: 27px;
      }
    }

    .descarca-pdf {
      button {
        width: 300px;
        height: 50px;
        border-radius: 10px;
      }
    }
  }
}

@media screen and (min-width: 1050px) {
  #meniu {
    .menu-text {
      p {
        text-align: center;
      }
    }
  }
}
@media screen and (min-width: 1400px) {
  #meniu {
    .menu-text {
      margin-bottom: 1.4em;
      p {
        font-size: 36px;
        margin-left: 58px;
        text-align: left;
      }
    }

    .descarca-pdf {
      justify-content: flex-start;
      margin-left: 58px;

      button {
        width: 382px;
        height: 48px;
        font-size: 30px;
      }
    }
  }
}
