.ore-deschidere-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 18px;

  .ore-deschidere {
    display: flex;
    flex-direction: row;
    gap: 19px;
    p {
      font-size: 14px;
    }
    .left-line {
      align-self: center;
      width: 25px;
      height: 0px;
      border: 1px solid #f5bd02;
      margin-right: 7px;
    }

    .right-line {
      align-self: center;
      width: 25px;
      height: 0px;
      border: 1px solid #f5bd02;
      margin-left: 7px;
    }
    a {
      color: transparent;
      .find-us {
        position: relative;
        color: #f5bd02;
        font-weight: 500;

        &::after {
          content: url(../../assets/Link.svg);
          bottom: 25%;
          left: 100.3%;
          position: absolute;
        }
      }
    }
  }
}

@media screen and (min-width: 375px) {
  .ore-deschidere-info {
    .ore-deschidere {
      .right-line,
      .left-line {
        width: 45px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .ore-deschidere-info {
    .ore-deschidere {
      .left-line {
        align-self: center;
        width: 110px;
        height: 0px;
        border: 1px solid #f5bd02;
        margin-right: 7px;
      }

      .right-line {
        align-self: center;
        width: 110px;
        height: 0px;
        border: 1px solid #f5bd02;
        margin-left: 7px;
      }
    }
    .ore-deschidere p {
      font-size: 20px;
    }
  }

  .left-line,
  .right-line {
    width: 80px;
  }
}

@media screen and (min-width: 860px) {
  .ore-deschidere-info {
    .ore-deschidere {
      p {
        font-size: 23px;
      }
    }

    .left-line,
    .right-line {
      width: 80px;
    }
  }
}

@media screen and (min-width: 1400px) {
  .ore-deschidere-info {
    margin-bottom: 20px;
  }
}
