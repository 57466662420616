@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

body {
  background-color: #000410;
}
