footer {
  margin-top: 20px;
  background-color: #7b97fd02;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  text-align: center;

  .footer-socials {
    margin-bottom: 7em;
  }

  .footer-logo {
    margin-top: 10px;
    width: 245px;
    height: 72px;

    img {
      width: 100%;
    }
  }

  .footer-contact {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .visit-us {
      p {
        text-decoration: none;
        color: #d3d0d0;

        // &::after {
        //   content: url(../../assets/Link.svg);
        //   bottom: 25%;
        //   left: 100.3%;
        //   position: absolute;
        // }
      }
    }

    h3 {
      font-size: 32px;
      font-weight: 500;
    }

    .e-mail-contact,
    .phone-contact {
      text-decoration: none;
      color: #d3d0d0;
      font-weight: 500;
    }
  }

  .footer-socials {
    display: flex;
    flex-direction: column;
    margin-top: 45px;
    gap: 5px;

    h3 {
      font-size: 32px;
      font-weight: 500px;
    }
    .follow-us {
      font-weight: 500;
      color: #d3d0d0;
    }
  }

  .socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 64px;
    margin-top: 8px;
    margin-bottom: 6px;
  }
}

@media screen and (min-width: 680px) {
  footer {
    .footer-socials {
      margin-bottom: 0;
    }
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: space-evenly;
    margin: 10px;

    .footer-socials {
      h3 {
        align-self: center;
        justify-self: center;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  footer {
    justify-content: space-between;
  }
}

@media screen and (min-width: 750px) {
  footer {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (min-width: 1000px) {
  footer {
    justify-content: space-around;
  }
}
