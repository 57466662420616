.acasa-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 5px;
  gap: 8px;

  .slogan {
    h1 {
      font-weight: 400;
      font-size: 28px;
      color: #f5bd02;
    }
  }

  .poveste {
    width: 310px; // 360
    p {
      font-size: 15px;
      font-weight: 500;
    }
  }
}

@media screen and (min-width: 360px) {
  .acasa-text-container {
    .poveste {
      width: 360px;
    }
  }
}

@media screen and (min-width: 768px) {
  .acasa-text-container {
    .slogan {
      h1 {
        font-weight: 400;
        font-size: 30px;
      }
    }
    .poveste {
      width: 500px;

      p {
        font-size: 17.5px;
      }
    }
  }
}

@media screen and (min-width: 860px) {
  .acasa-text-container {
    .slogan {
      h1 {
        font-weight: 400;
        font-size: 35px;
      }
    }
    .poveste {
      width: 500px;

      p {
        font-size: 18px;
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .acasa-text-container {
    .slogan {
      h1 {
        font-weight: 400;
        margin-bottom: 15px;
        font-size: 44px;
      }
    }
    .poveste {
      width: 655px;
      margin-bottom: 15px;

      p {
        font-size: 22px;
      }
    }
  }
}
