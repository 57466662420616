.imagini-john-container {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 29px;
  img {
    width: 100px;
    height: 90px;
    border-radius: 10px;
    z-index: -1;
  }
}

@media screen and (min-width: 758px) {
  .imagini-john-container img {
    width: 150px;
    height: 130px;
  }
}
@media screen and (min-width: 1400px) {
  .imagini-john-container {
    justify-content: space-between;
  }
  .imagini-john-container img {
    width: 210px;
    height: 180px;
  }
}
@media screen and (min-width: 1600px) {
  .imagini-john-container {
    flex-wrap: nowrap;
    gap: 20px;
  }
  .imagini-john-container img {
    width: 260px;
    height: 200px;
  }
}
@media screen and (min-width: 1800px) {
  .imagini-john-container {
    flex-wrap: nowrap;
    gap: 40px;
  }
  .imagini-john-container img {
    width: 280px;
    height: 220px;
  }
}
