.bg-img {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 116px;
  height: 182px;
  z-index: -99999999;
}
.bg-img-pasta {
  display: none;
  top: -5%;
  left: -10%;
  position: absolute;
  z-index: -999999;
}

@media screen and (min-width: 530px) {
  .bg-img {
    display: block;
    width: 140px;
    height: 210px;
  }
}
@media screen and (min-width: 630px) {
  .bg-img {
    width: 190px;
    height: 250px;
  }
}
@media screen and (min-width: 730px) {
  .bg-img {
    width: 230px;
    height: 280px;
  }
}

@media screen and (min-width: 850px) {
  .bg-img {
    width: 260px;
    height: 340px;
  }
}

@media screen and (min-width: 1050px) {
  .bg-img {
    width: 200px;
    height: 300px;
  }
  .divide {
    margin-left: 224px;
  }
}
@media screen and (min-width: 1200px) {
  .bg-img {
    width: 290px;
    height: 330px;
  }
  .divide {
    margin-left: 224px;
  }
}
@media screen and (min-width: 1400px) {
  .bg-img {
    width: 290px;
    height: 440px;
  }
  .divide {
    margin-left: 224px;
  }
}
@media screen and (min-width: 1500px) {
  .bg-img {
    width: 340px;
    height: 440px;
  }
  .divide {
    margin-left: 224px;
  }
}
@media screen and (min-width: 1600px) {
  .bg-img {
    width: 380px;
    height: 530px;
  }
  .divide {
    margin-left: 224px;
  }
}
@media screen and (min-width: 1700px) {
  .bg-img {
    width: 390px;
    height: 584px;
  }
  .divide {
    margin-left: 224px;
  }
}
